::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #666;
}

.MuiCardHeader-root{
  justify-content: 'space-between';
  width: 100%;
}

.MuiAccordionSummary-content{
  margin: 0px !important;
}

.progressBar{
  position: absolute; 
  top: 36px; 
  right: 10px;
}

.requestDetails{
  font-size: 1.3118rem;
  line-height: 1.334;
}

.MuiStepConnector-root{
  left: calc(-50% + -3px) !important;
}

.MuiStep-root{
  padding-right: 0;
}

@media only screen and (max-width: 850px) {
  .progressBar{
    position: relative;
    top: 0px;
  }

  .progressBarGrid{
    position: relative;
    top: 15px;
    justify-content: center;
  }
}

.rdg-header-row {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-weight: 500;
  font-size: 0.875rem;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-align: left;
  white-space: nowrap;
}

.rdg-header-row .rdg-cell{
  z-index: 2;
}

.rdg-header-row .rdg-cell-frozen {
  z-index: 3;
}

.rdg-cell .MuiOutlinedInput-root {
  border-radius: 0px;
}

.rdg-cell .MuiOutlinedInput-input {
  padding: 6px 14px;
}

.rdg-light .rdg-header-row {
  color: #172b4d;
  background-color: #f4f5f7;
}

.rdg-dark .rdg-header-row {
  color: #fff;
  background-color: #000;
  border-top: none;
}

.rdg-cell-required {
  border: 1px solid red !important;
}

.rdg-cell-right-border {
  border-right-style: solid !important;
  border-left-style: solid !important;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.rdg-header-row-custom {
  --rdg-grid-row-start: 1;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
  font-weight: 500;
  font-size: 0.875rem;
  text-align: left;
  color: #172b4d;
  white-space: nowrap;
  background-color: #f4f5f7;
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
  height: 36px;
}

.rdg .rdg-cell-frozen  {
  z-index: 3;
}

.rdg-light .rdg-header-row-custom {
  color: #172b4d;
  background-color: #f4f5f7;
}

.rdg-dark .rdg-header-row-custom {
  color: rgba(221, 221, 221);
  background-color: #000;
  border-bottom: 0.2px solid #444;
  border-top: 0.2px solid #444;
}

.rdg {
  border-spacing: 0;
}

.rdg .rdg-row {
  border-collapse: collapse;
  border-spacing: 0;

}

.rdg .rdg-cell {
  border-left-style: hidden;
  border-right-style: hidden;
}

.rdg-light .rdg-row-odd {
  background-color: #fff;
}

.rdg-light .rdg-row-even {
  background-color: rgba(0, 0, 0, 0.04);
}

/* Custom style for the dark mode  */
.rdg-dark .rdg-row-even {
  background-color: rgba(255, 255, 255, 0.08);
  box-shadow: 0px 1px 2px rgb(0 0 0 / 12%), 0px 0px 0px 1px rgb(0 0 0 / 5%);  
}

.rdg-dark .rdg-row:hover {
  background-color: rgba(199, 153, 0, 0.15);
}

